/**
 * Function to grab values from index db storage
 * @param {*} dbName 
 * @param {*} storeName 
 * @param {*} key - takes single string or array of strings
 * @param {*} storeIndex 
 * @returns {*} - an object with key value pairs
 */
const getValue = ( dbName, storeName, key, storeIndex = "name") => {

    return new Promise( (resolve, reject) => {    
        try {

            // Open a connection to the database
            let request = window.indexedDB && typeof indexedDB.open === "function" ? indexedDB.open( dbName ) : "",
                dbExists = true,
                returnValues = {};

            if ( typeof request !== "object" ) {          
                
                console.log( LOG_PREFIX + " -- Error: indexDB not supported");
                reject( {} );    
                return;             

            }

            request.onupgradeneeded = ( event ) => {
                
                // This event means the database did not exist and is being created
                dbExists = false;
                event.target.transaction.abort(); // Abort the creation
                
                console.log( LOG_PREFIX + "request.onupgradeneeded-- Error: DB does not exist", event);                                   
                reject( {} ); 
                return;

            };

            //catch all errors
            request.onerror = function( event ) {

                console.log( LOG_PREFIX + "request.onerror-- catch all request error", event);                                 
                reject( {} );
                return;

            };

            request.onsuccess = function( event ) {
                
                if ( !dbExists ) {

                    console.log( LOG_PREFIX + "request.onsuccess-- Error: database does not exist", event );                      
                    reject( {} );
                    return;

                }

                let db = event.target.result,
                    transaction,
                    store,
                    index,
                    getRequest;  
                
                db.onerror = ( event ) => {                  

                    console.log( LOG_PREFIX + "db.onerror -- Error", event ); 
                    db.close();  
                    reject( {} ); 
                    return;

                };

                db.onversionchange = (event) => {
                    
                    db.close();
                    console.log( LOG_PREFIX + "db.onversionchange -- A new version of this page is ready", event ); 
                    reject( {} ); 
                    return;

                };

                try {
                    
                    if ( !db.objectStoreNames.contains(storeName) ) {

                        console.log( LOG_PREFIX + "objectStore does not exist" );
                        reject({});
                        return;

                    }

                    transaction = db.transaction( storeName, "readonly" );
                    db.close();

                    transaction.onerror = ( event ) => {                   
                        
                        console.log( LOG_PREFIX + "transaction.onerror -- Error", event );
                        reject( {} );
                        return;
                    
                    };
    
                    store = transaction.objectStore( storeName );
    
                    store.onerror = ( event ) => {                   

                        console.log( LOG_PREFIX + "store.onerror -- Error", event );
                        reject( {} ); 
                        return;
                    
                    };
    
                    index = store.index( storeIndex );

                    if ( Array.isArray( key ) ) {

                        index.openCursor().onsuccess = ( event ) => {

                            const cursor = event.target.result;

                            if ( cursor ) {

                                if ( key.includes( cursor.key ) ) {

                                    returnValues[ cursor.key ] = cursor.value.value;

                                }
                                
                                cursor.continue();

                            } else {

                                resolve( returnValues );

                            }
                        };

                    } else {

                        getRequest = index.openCursor( key );

                        getRequest.onerror = ( event ) => {

                            console.log(LOG_PREFIX + "getRequest.onerror -- Error", event);
                            reject( {} ); 
                            return;

                        };

                        getRequest.onsuccess = function ( event ) {
                            
                            returnValues[ key ] = event?.target?.result?.value?.value;

                            resolve( returnValues );

                        };
                    }

                } catch ( error ) {

                    console.log( LOG_PREFIX + " -- request.onsuccess CATCH ALL Error: ", error );
                    reject( {} ); 
                                   
                }
                          
            };
            
        } catch ( e ) {

            console.log( LOG_PREFIX + " -- getValue CATCH ALL Error", e );
            reject( {} );            

        }

    });

  };

export {
	getValue
};