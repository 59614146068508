import * as beaconController from "./beaconController";
import * as utilities from "./utilities";
import * as appData from "./appData";
import * as eventProcessing from "./eventProcessing";
import * as stash from "../src/stash";

let processPage = async function() {

    let ddoInstance = await utilities.snapshotInstanceOfDigitalData( window.digitalData );
	let ddo = ddoInstance.ddo;

	//check if eventData exists to report
	let newEvents = eventProcessing.getNewEvents( ddo );

	appData.addUpdateCache( "triggerPageView", true );

	// stash/preload/report new not-yet-processed events
	for ( const evt in newEvents ) {

		if ( newEvents.hasOwnProperty( evt ) ) {
			await eventProcessing.processEvent( newEvents[ evt ].sIndex, ddoInstance );
		}

	}

	if ( appData.getFromCache( "triggerPageView" ) ) {

		// Replace spaces with underscores for daMap node processing
		let pageLoadEvent = typeof ddo.page.pageInfo.pageLoadEvent === "string" ? ddo.page.pageInfo.pageLoadEvent.toLowerCase().replace( / /g, '_' ) : '';

		stash.onLoad();
		appData.addUpdateCache( "sponsoredMetrics", "" );
		
		// Reset impression suppression flag, for all non-pagination pages (introduced with nucleus)
		if ( pageLoadEvent !== 'pagination' ) {			
			appData.addUpdateCache( "suppressProductImpression", false );
		}

		// Page load reporting will only have one node ( the secondary node ), which is therefore the trigger.  Hence why we are passing an emptry string as the primaryNode (ie 3rd) parameter.  This is unline event reporting, which will have a trigger comprised of a primary and a seondary node.
        await beaconController.report( ddo, ddoInstance.id, "", pageLoadEvent );

		let internalEvents = appData.getInternalEvents();

		if ( internalEvents.length ) {
			window.setTimeout( eventProcessing.processInternalEvents, 2000, ddo );
		}

	}

};

// Calls processPage()
let waitForPX = function () {

	let pxbsPoll;
	let pollCount = 0;

	let checkPXBS = function () {

		if ( _aape.pxbs || pollCount > 19 ) {

			window.clearInterval( pxbsPoll );
			processPage();

		}

		pollCount = pollCount + 1;

	};

	if ( _aape.pxbs ) {

		processPage();

	}
	else {

		pxbsPoll = window.setInterval( function () {
			checkPXBS();
		}, 100 );

	}

};

let beginPath = function() {

	// set a flag to indicate that the push has occurred.  We could make this a counter if we want to track how many times this happens
	appData.addUpdateCache( "pageDataPushed", true );

	waitForPX(); //length after adding

}

let sendNotification = function() {

	// if ( _aape.PUB_SUB ) {
		_aape.PUB_SUB.publish( "thdcoreanalytics|pageDataPushed" );
		console.log( LOG_PREFIX + ' -- _aape.PUB_SUB.publish( "thdcoreanalytics|pageDataPushed" );' );
	// }

}

let setUpListener = function() {

	if ( _aape.PUB_SUB.broadcastArchive.length ) {

		let broadcastArchive = _aape.PUB_SUB.broadcastArchive;

		broadcastArchive.forEach( function( broadcast ) {

			if ( broadcast.name === "thdcoreanalytics|pageDataPushed" ) {

				beginPath();

			}

		} );

	}

	_aape.PUB_SUB.subscribe( 'thdcoreanalytics|pageDataPushed', function() {

		beginPath();

	} );

}

// Internal method returns custom array object with a pub sub publish on push
let pageDataArray = function () {

	let DataArray = function () {
		let arr = [];
		arr.push = function () {

			console.log( LOG_PREFIX + " -- pageDataArray", arr );

			sendNotification();

			return arr.length;
		};
		return arr;
	};
	return new DataArray();
};

// API method exposed for external IT teams to trigger analytics page reporting
// This is essentially an event listener.  The ddo reference/param here isn't actually used in a practical sense.
// An snapshot instance of digitalData is generated directly from window.digitalData in the processPage method.
// We recognize that is isn’t ideal, but it seems to be the most reliable solution at this point in time.
let sendPageDataToAnalytics = function () {

	sendNotification();

};

export {
	pageDataArray,
	sendPageDataToAnalytics,
	setUpListener
};