/**
 * Reads the THD_AAPE full local storage object
 * @param {string} key
 * @returns {*}
 */
let getFullAnalyticsObject = function() {

	let storageObject;
	let returnVal = {};

	try {

		// The getItem() method of the Storage interface, when passed a key name, will return that key's value, or null if the key does not exist, in the given Storage object.
		storageObject = typeof Storage !== "undefined" ? localStorage.getItem( "THD_AAPE" ) : "";

		if ( storageObject ) {

			let parsedObject = JSON.parse( storageObject );

			returnVal = parsedObject;

		}

	} catch ( e ) {
		console.log( LOG_PREFIX + " -- Error reading Storage: ", e );
	}

	return returnVal;

};

/**
 * Reads the THD_AAPE local storage
 * @param {string} key
 * @returns {*}
 */
let getItem = function( key ) {

	let storageObject = getFullAnalyticsObject();
	let result =  ( Object.hasOwn( storageObject, key ) && storageObject[ key ].value ) ? storageObject[ key ].value : "";

	// console.log( LOG_PREFIX + " -- GET STORAGE ITEM -- key: " + key + " -- value: " + result );

	return result;

};

/**
 * Removes an item from local storage
 * @param {string} key
 * @returns {*}
 */
let removeItem = function( key ) {

	if ( key !== "THD_AAPE" ) {

		let storageObject = getFullAnalyticsObject();
		delete storageObject[ key ]; 

		localStorage.setItem( "THD_AAPE", JSON.stringify( storageObject ) );

	}

};

/**
 * Removes all analytics items from local storage
 * @param {string} key
 * @returns {*}
 */
let removeAllItems = function() {

	localStorage.removeItem( "THD_AAPE" );

};

/**
 * Write value to storage or cookie as available
 * @param {string} key
 * @param {string} value
 * @param {number} lifespan in milliseconds
 */
let setItem = function( key, value, lifespan = 1800000 ) { // Default lifespan is 30 minutes

	if ( value !== null && value !== undefined ) {

		let currentObject = getFullAnalyticsObject();
		let expires = Date.now() + lifespan;

		// let temp = new Date ( expires );
		// console.log( LOG_PREFIX + " -- SET STORAGE ITEM -- key: " + key + " -- expiration: " + expires + " -- value: " + value );

		currentObject[ key ] = { "value": value, "expires": expires };

		try {
			if ( typeof ( Storage ) !== "undefined" ) {
				localStorage.setItem( "THD_AAPE", JSON.stringify( currentObject ) );
			}
		}
		catch ( e ) {
			console.log( LOG_PREFIX + " -- Error writing to Storage: ", e );
		}

	}

};

let checkForExpiredItems = function (){

	let currentObject = getFullAnalyticsObject();
	let currentTimestamp = Date.now();

	for ( let item in currentObject ){

		// console.log( LOG_PREFIX + " -- " + item + " expired: ", ( item.expires <= Date.now() ) );

		if ( currentObject[ item ].expires <= currentTimestamp ) {

			removeItem( item );

		}

	}

};

export {
	setItem,
	getItem,	
	removeItem,
	removeAllItems,
	checkForExpiredItems
};