import * as appData from "../src/appData";
import * as eventProcessing from "../src/eventProcessing";
import * as storage from "../src/storage";

/* eslint-disable */
var initListeners = function ( ddo ) {
	
	/*Intergrations with PX defender tracking */
	document.addEventListener( '__px_event', ( e ) => {
		if ( e.detail && e.detail.event && e.detail.event === 'page_infected' && typeof ( e.detail.protected ) !== 'undefined' ) {

			// console.log( LOG_PREFIX + " -- PerimeterX Defender Event: ", e.detail.protected );

			let pxCookie = storage.getItem( 'pxdefender' );
			storage.setItem( 'pxdefender', e.detail.protected );

			if ( pxCookie === '' && appData.getFromCache( "pageViewSent" ) ) {

				appData.addInternalEvents( {
					"category": {
						"primaryCategory": "interaction"
					},
					"eventInfo": {
						"eventName": "perimeterx defender"
					},
					"eventData": e.detail.protected
				} );

				eventProcessing.processInternalEvents( ddo );
			}

		}
	} );

}
/* eslint-enable */
export {
	initListeners
};
