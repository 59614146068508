// Pub/Sub Factory Function
// In factory functions ‘this’ refers to the parent object
const init = function () {

    return {

        subscriptions: _aape.PUB_SUB.subscriptions,
        broadcastArchive: _aape.PUB_SUB.getArchive(),

        // subscribe to an event
        subscribe: function ( name, func ) {

			console.warn( LOG_PREFIX + " -- _T.PUB_SUB is deprecated.  Please migrate to _aape.PUB_SUB." );

			let id = _aape.PUB_SUB.subscribe( name, func );

            return id;

        },

        // unsubscribe from an event
        unsubscribe: function ( id ) {

			console.warn( LOG_PREFIX + " -- _T.PUB_SUB is deprecated.  Please migrate to _aape.PUB_SUB." );

			_aape.PUB_SUB.unsubscribe( id );

        },

        // publish an event
        publish: function ( name, args ) {

			console.warn( LOG_PREFIX + " -- _T.PUB_SUB is deprecated.  Please migrate to _aape.PUB_SUB." );

            _aape.PUB_SUB.publish( name, args );

		}

    };

};

export {
    init
};


