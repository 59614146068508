import { getValue } from './indexdb';
import { getItem, setItem } from './storage';
const remoteUtils = await import( 'AAPE_Sync/utilities' );

/*
Prod - x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu - https://www.homedepot.com/uds/v1/mapping?key=x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu&anchor=03ed1de74b71aa700s&type=ecrId,hhID
LLC - R0QTGGrYosXcuas42kdAx7RVGowBhveJ - https://uds-api.hd-personalization-stage.gcp.homedepot.com/uds/v1/mapping?key=R0QTGGrYosXcuas42kdAx7RVGowBhveJ&anchor=03ed1de74b71aa700s&type=ecrId,hhID
*/
const _env = ( remoteUtils.isProdDeployment() ? 'prod' : 'prod' ), // TODO: the dev URL seems to be broken, so we are using the prod URL for both dev and prod at least for now with Web SDK testing.
	_UDSConf = {
		"prod": { "key": "x540zSAbXJfU1e1OIhrNRk9t0aqF0vpu", "endpoint": "www.homedepot.com/uds/v1/mapping" },
		"dev": { "key": "R0QTGGrYosXcuas42kdAx7RVGowBhveJ", "endpoint": "uds-api.hd-personalization-stage.gcp.homedepot.com/uds/v1/mapping" }
	};

const init = () => {

	let ecridUDSCalled = getItem( "ecridUDSCalled" );

	console.log( LOG_PREFIX + " -- ecridUDSCalled", ecridUDSCalled );

	if ( _aape.svocid && !ecridUDSCalled ) {

		getECRIDFromSVOCID( _aape.svocid );
		setItem( 'ecridUDSCalled', true ); // (expires in 30 minutes)

	}
	
	getIDsFromIndexDB();

};

const getECRIDFromSVOCID = async ( svocid ) => {

	try {

		const controller = new AbortController(),
			signal = controller.signal,
			url = `https://${ _UDSConf[ _env ].endpoint }?key=${ _UDSConf[ _env ].key }&anchor=${ svocid.toString().toUpperCase() }&type=ecrId,hhId`;

		setTimeout( () => controller.abort(), 5000 );

		fetch( url, { signal } )
			.then( response => response.json() )
			.then( data => {

				console.log( LOG_PREFIX + " -- [UDS API]:", data );

				if ( typeof ( data.ecrId ) !== 'undefined' ) {
					setItem( 'ecridFromSVOCID', data.ecrId ); // (expires in 30 minutes)        
				}
				if ( typeof ( data.hhId ) !== 'undefined' ) {
					setItem( 'hhidFromSVOCID', data.hhId ); // (expires in 30 minutes)
				}
				return 'ecrID=' + ( data.ecrId ? data.ecrId : '' ) + ',' + 'hhId=' + ( data.hhId ? data.hhId : '' );

			} )
			.catch( error => {

				console.error( '[UDS API]: There has been a problem with your fetch operation:', error );
				return "";

			} );

	} catch ( e ) {

		console.log( LOG_PREFIX + " -- [UDS API]: ERROR", e );
		return "";

	}

};

const getIDsFromIndexDB = async ( ) => {

	let mrSyncData = await getValue("mr", "syncs", ["_mr_adobe_aam_uuid","_mr_neustar_fabrickId"]).then(data => data).catch(error => error);
	// Set this as late in the beacon exexctuion as possible						
	_aape.metrics.eVar169 = ( typeof ( mrSyncData ) === 'object' && typeof ( mrSyncData["_mr_adobe_aam_uuid"] ) === 'string' ? mrSyncData["_mr_adobe_aam_uuid"] : 'cookie not set' );

	/* passing of Neustar Fabrick id */			
	_aape.metrics.eVar244 = ( typeof ( mrSyncData ) === 'object' && typeof ( mrSyncData["_mr_neustar_fabrickId"] ) === 'string' ? mrSyncData["_mr_neustar_fabrickId"] : '' );

};

export {
	init
};