import * as utilities from "../src/utilities";

// Pub/Sub Factory Function
// In factory functions ‘this’ refers to the parent object
const init = function () {

	// let broadcastArchive = [];

    return {

        subscriptions: {},
		broadcastArchive: [],

		// return the broadcast archive
		getArchive: function() {

			return this.broadcastArchive;

		},

		// reset the broadcast archive
		resetArchive: function() {

			this.broadcastArchive = [];

		},

        // subscribe to an event
        subscribe: function ( name, func ) {

			console.log( LOG_PREFIX + " -- PUB_SUB this context: ", this );

            let id = utilities.getUUID();

            if ( !this.subscriptions[ name ] ) {
                this.subscriptions[ name ] = [];
            }

            this.subscriptions[ name ].push( {
                "id": id,
                "action": func
            } );

            return id;

        },

        // unsubscribe from an event
        unsubscribe: function ( id ) {

            for ( let sub in this.subscriptions ) {

                let subscribers = this.subscriptions[ sub ];

                for ( let i = 0; i < subscribers.length; i++ ) {

                    if ( subscribers[ i ].id === id ) {
                        subscribers.splice( i, 1 );
                        return;
                    }

                }

            }

        },

        // publish an event
        publish: function ( name, args ) {

            let audience = this.subscriptions[ name ];

            this.broadcastArchive.push( {
                "name": name,
                "data": args
            } );

            if ( audience ) {

                audience.forEach( subscriber => {

                    let action = subscriber.action;

                    if ( typeof action === 'function' ) {
                        action.call( null, args );
                    }

                } );

            }

        }

    };

};

export {
    init
};
